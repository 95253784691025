<!--
 * @Author: 梁平贤
 * @Date: 2020-07-04 16:56:43
 * @LastEditors: zhulin
 * @LastEditTime: 2020-08-20 14:41:34
 * @Description:通知回执
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\noticeReceipt\index.vue
-->
<template>
  <div class="noticeReceipt">
    <div class="top-head">
      <div class="col-tit"><span class="txts">通知回执({{receiptData.commParams.receipt1}} / {{receiptData.commParams.totalNum}})</span></div>
      <en-icon :name="showList ? 'iconzhankai' : 'iconshouqi'" size="12" class="show-btn" @click.native="showList = !showList"> </en-icon>
    </div>
    <div class="list-main" v-if="showList" >
      <div class="list" v-if="this.receiptData.records.length > 0">
        <en-table :loading="loadingReceipt" :data="this.receiptData.records" @filter-sort-change="handleFilterSortChange">
          <en-table-column type="index" width="60px"></en-table-column>
          <en-table-column prop="receiverName" label="回执对象" :filter-sort-config="{ sortable: false, filterable: true }">
            <template slot-scope="scope">
              <div class="table-slot">
                <en-user-logo :user-name="scope.row.receiverName" :image-url="scope.row.receiverUserLogo" size="30"></en-user-logo>
                <span>{{ scope.row.receiverName }} </span>
              </div>
            </template>
          </en-table-column>
          <en-table-column prop="receiptName" label="回执状态" :filter-sort-config="{ sortable: false, filterable: true }">
            <template slot-scope="scope">
              <el-tag :type="scope.row.receiptName == '待回执' ? '' : 'success'">{{ scope.row.receiptName }}</el-tag>
            </template>
          </en-table-column>
          <en-table-column prop="receiptTime" label="回执时间"> </en-table-column>
        </en-table>
        <en-pagination :page-model="pageModel" @change="handlePageChanged"></en-pagination>
      </div>
      <en-result type="NoData" v-else></en-result>
    </div>
  </div>
</template>

<script>
import { flowAndLog } from "@/api/businessModel";

export default {
  name: "BMNoticeReceiptColumn",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      receiptData: {
        records: [],
        commParams: {}
      }, // 请求到的流程数据
      showList: true,
      mdInfo: {},
      loadingReceipt: false,
      pageModel: {},
      params: {}
    };
  },
  mounted() {
    this.mdReceiptList();
  },
  methods: {
    handleFilterSortChange({ filterData }) {
      if (!filterData) {
        this.params = {};
      } else {
        const data = JSON.parse(filterData);
        data.forEach((item) => {
          this.params[item.field] = item.value;
        });
      }
      this.mdReceiptList();
    },
    /**
     * @description: 查询通知回执数据
     * @param {}
     */
    async mdReceiptList(pageNo, pageSize) {
      try {
        this.loadingReceipt = true;
        const rsp = await flowAndLog.queryReceiptList({
          businessKey: this.businessData.id,
          receiverName: this.params.receiverName,
          receiptName: this.params.receiptName,
          pageSize: pageSize || 10,
          pageNo: pageNo || 1
        });
        this.receiptData = rsp;
        this.pageModel = {
          pageNo: this.receiptData.pageNo || 1,
          pageSize: this.receiptData.pageSize || 10,
          total: this.receiptData.total,
          totalPages: this.receiptData.totalPages
        };
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingReceipt = false;
      }
    },
    /**
     * @description: 切换页码
     */
    handlePageChanged({ pageSize, pageNo }) {
      this.mdReceiptList(pageNo, pageSize);
    }
  }
};
</script>

<style lang="scss" scoped>
.noticeReceipt {
  .table-slot {
    display: flex;
    .en-user-logo {
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  .top-head {
    height: 48px;
    text-align: left;
    zoom: 1;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .txts {
      font-weight: bold;
      float: left;
      line-height: 48px;
    }
    .show-btn {
      float: right;
      color: #66a7e5;
      margin: 16px 18px 0 0;
      cursor: pointer;
    }
  }
}
</style>
