var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "noticeReceipt" }, [
    _c(
      "div",
      { staticClass: "top-head" },
      [
        _c("div", { staticClass: "col-tit" }, [
          _c("span", { staticClass: "txts" }, [
            _vm._v(
              "通知回执(" +
                _vm._s(_vm.receiptData.commParams.receipt1) +
                " / " +
                _vm._s(_vm.receiptData.commParams.totalNum) +
                ")"
            ),
          ]),
        ]),
        _c("en-icon", {
          staticClass: "show-btn",
          attrs: {
            name: _vm.showList ? "iconzhankai" : "iconshouqi",
            size: "12",
          },
          nativeOn: {
            click: function ($event) {
              _vm.showList = !_vm.showList
            },
          },
        }),
      ],
      1
    ),
    _vm.showList
      ? _c(
          "div",
          { staticClass: "list-main" },
          [
            this.receiptData.records.length > 0
              ? _c(
                  "div",
                  { staticClass: "list" },
                  [
                    _c(
                      "en-table",
                      {
                        attrs: {
                          loading: _vm.loadingReceipt,
                          data: this.receiptData.records,
                        },
                        on: {
                          "filter-sort-change": _vm.handleFilterSortChange,
                        },
                      },
                      [
                        _c("en-table-column", {
                          attrs: { type: "index", width: "60px" },
                        }),
                        _c("en-table-column", {
                          attrs: {
                            prop: "receiverName",
                            label: "回执对象",
                            "filter-sort-config": {
                              sortable: false,
                              filterable: true,
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-slot" },
                                      [
                                        _c("en-user-logo", {
                                          attrs: {
                                            "user-name": scope.row.receiverName,
                                            "image-url":
                                              scope.row.receiverUserLogo,
                                            size: "30",
                                          },
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.receiverName) + " "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2224621214
                          ),
                        }),
                        _c("en-table-column", {
                          attrs: {
                            prop: "receiptName",
                            label: "回执状态",
                            "filter-sort-config": {
                              sortable: false,
                              filterable: true,
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type:
                                            scope.row.receiptName == "待回执"
                                              ? ""
                                              : "success",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.receiptName))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3961148946
                          ),
                        }),
                        _c("en-table-column", {
                          attrs: { prop: "receiptTime", label: "回执时间" },
                        }),
                      ],
                      1
                    ),
                    _c("en-pagination", {
                      attrs: { "page-model": _vm.pageModel },
                      on: { change: _vm.handlePageChanged },
                    }),
                  ],
                  1
                )
              : _c("en-result", { attrs: { type: "NoData" } }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }